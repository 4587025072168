<template>
  <div id="app">
    <navigation-header />
    <router-view />
    <fixed-bottom />
    <!-- <el-dialog title="咨询预约" :visible.sync="$store.state.dialogVisible" class="dialog">
      <p class="title">
        为了我们能够更好的服务您，请填写以下信息，我们将在24小时内为您提供一对一的解决方案及产品试用服务，您也可以拨打免费服务热线：
        <a href="tel:400-878-6390">400-878-6390</a>
        进行咨询
      </p>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="ruleForm" size="small">
        <el-form-item label="姓名" prop="name">
          <el-input v-model.trim="ruleForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model.trim="ruleForm.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="company">
          <el-input v-model.trim="ruleForm.company" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-autocomplete
            v-model="ruleForm.email"
            style="width: 100%"
            placeholder="请输入邮箱"
            :fetch-suggestions="querySearchEmail"
            :trigger-on-focus="false"
          />
        </el-form-item>
      </el-form>
      <div>您关注的产品</div>
      <el-radio-group v-model="ruleForm.product">
        <el-radio label="1">缴费系统</el-radio>
        <el-radio label="2">绩效系统</el-radio>
        <el-radio label="3">熟悉营销平台</el-radio>
        <el-radio label="4">特色业务平台</el-radio>
      </el-radio-group>
      <div>
        <el-button type="primary" @click="submitForm('ruleForm')" class="button" size="small">提交</el-button>
      </div>
      <p class="tips">（您提交的信息将会受到严格保密）</p>
    </el-dialog> -->
    <el-dialog title="服务咨询" :visible.sync="$store.state.dialogVisible" class="dialog">
      <p class="title" style="font-size: 17px">
        为了我们能够更好的服务您，扫描下方二维码，我们将在24小时内为您提供一对一的解决方案及产品试用服务，您也可以拨打免费服务热线：
        <a href="tel:400-878-6390">400-878-6390</a>
        进行咨询
      </p>
      <img class="wx-code" src="./assets/images/wx.jpg" alt="" style="width: 140px; height: 140px; display: block; margin: 0 auto" />
    </el-dialog>
  </div>
</template>

<script>
import NavigationHeader from './components/Header/index.vue'
import FixedBottom from './components/FixedBar/index.vue'
export default {
  components: { NavigationHeader, FixedBottom },
  data() {
    return {
      ruleForm: {
        name: '',
        phone: '',
        company: '',
        email: '',
        product: '1'
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              const reg = /^1[3-9]{1}[0-9]{9}$/
              if (value === '') {
                callback(new Error('请输入手机号'))
              } else if (!reg.test(value)) {
                callback(new Error('请输入正确手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        company: [{ required: true, message: '请输入单位名称', trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log('提交', this.ruleForm)
        }
      })
    },
    querySearchEmail(queryString, callback) {
      const emailList = [{ value: '@qq.com' }, { value: '@126.com' }, { value: '@163.com' }]
      let results = []
      const queryList = []
      emailList.map(item => {
        return queryList.push({ value: queryString.split('@')[0] + item.value })
      })
      results = queryString ? queryList.filter(this.createFilter(queryString)) : queryList
      callback(results)
    },
    createFilter(queryString) {
      return item => {
        return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    }
  }
}
</script>

<style lang="scss">
.dialog {
  .title {
    margin-bottom: 20px;
    a {
      cursor: pointer;
      color: #2463ef;
      font-weight: bold;
    }
  }
  .el-radio-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    margin: 10px 0;
    .el-radio {
      display: flex;
      align-items: center;
    }
  }
  .button {
    display: block;
    width: 40%;
    margin: 0 auto;
  }
  .tips {
    text-align: center;
    font-size: 13px;
    color: #999;
    margin-top: 20px;
  }
  .el-dialog {
    width: 30% !important;
    .el-radio {
      margin-right: 20px;
    }
  }
}
.ruleForm {
  width: 60%;
  margin: 0 auto;
}
@media all and (max-width: 1650px) {
  .dialog .el-dialog {
    width: 40% !important;
  }
}
@media all and (max-width: 1550px) {
}
@media all and (max-width: 1450px) {
}
@media all and (max-width: 1350px) {
}
@media all and (max-width: 1270px) {
  .el-radio-group {
    flex-wrap: wrap;
    justify-content: center;
    .el-radio {
      width: 40% !important;
      margin-right: 0 !important;
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 10px;
      }
    }
  }
}
@media all and (max-width: 1170px) {
}
</style>
