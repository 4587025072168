<template>
  <div id="cbutton" :style="{ background: color, color: color === '#ff9e00' ? '' : '#ff9e00' }" @click="hendleClick">{{ content }}</div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#ff9e00'
    }
  },
  methods: {
    hendleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
#cbutton {
  width: 150px;
  height: 46px;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
