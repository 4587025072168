import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('../views/index.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/bank',
    component: () => import('../views/bank.vue'),
    meta: { title: '银行绩效' }
  },
  {
    path: '/school',
    name: 'School',
    component: () => import('@/views/school'),
    meta: { title: '校园缴费' }
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('@/views/market'),
    meta: { title: '数字营销' }
  },
  {
    path: '/development',
    name: 'Development',
    component: () => import('@/views/development'),
    meta: { title: '开发平台' }
  },
  {
    path: '/about',
    name: 'About',
    redirect: '/about/company',
    component: () => import('@/views/about'),
    meta: { title: '关于我们' },
    children: [
      {
        path: '/about/user',
        name: 'User',
        component: () => import('@/views/about/user')
      },
      {
        path: '/about/company',
        name: 'Company',
        component: () => import('@/views/about/company')
      },
      {
        path: '/about/honor',
        name: 'Honor',
        component: () => import('@/views/about/honor')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
