<template>
  <div class="yx-navigation-header">
    <img class="yx-navigation-header-logo" src="../../assets/images/logo.png" alt="" />
    <ul class="yx-navigation-list-wrapper">
      <li v-for="(v, i) in navList" :key="i" @click="navigateTo(i)" :style="{ color: active === i ? variables.blue : '' }">
        {{ v.name }}
      </li>
      <li @click="phone">
        <span class="iconfont icon-dianhua" style="color: #2463ef; font-weight: bold">{{ phoneNum }}</span>
      </li>
      <li>
        <span class="" @click="$store.state.dialogVisible = true">免费试用</span>
      </li>
    </ul>
  </div>
</template>
<script>
import variables from '../../assets/styles/variables.module.scss'
export default {
  data() {
    return {
      phoneNum: '400-878-6390',
      navList: [
        { path: '/index', name: '首页' },
        { path: '/bank', name: '银行绩效' },
        { path: '/school', name: '校园缴费' },
        { path: '/market', name: '数字营销' },
        { path: '/development', name: '开发平台' },
        { path: '/about', name: '关于我们' }
        // { path: '/market', name: '数字营销' }
      ],
      active: 0,
      variables
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.navList.some((v, i) => {
          if (val.path.indexOf(v.path) !== -1) {
            this.active = i
            return true
          }
          return false
        })
      },
      immediate: true
    }
  },
  methods: {
    navigateTo(i) {
      this.$router.push(this.navList[i].path)
      this.active = i
    },
    phone() {
      location.href = 'tel:' + this.phoneNum
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/styles/variables.module';
.yx-navigation-header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2%;
  position: sticky;
  top: 0;
  z-index: 999;
  backdrop-filter: blur(12px);
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  transition: all 0.2s;
  .yx-navigation-header-logo {
    width: 136px;
    height: 40px;
    cursor: pointer;
  }
  .yx-navigation-list-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    li {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: normal;
      cursor: pointer;
      margin-right: 30px;
      &:hover {
        color: $blue;
        transition: all 0.2s;
      }
      &:last-child {
        color: $blue;
        font-weight: bold;
        span::before {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
