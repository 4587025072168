<template>
  <div class="flxed-bar" :style="{ opacity: scroll > 600 ? 1 : 0, zIndex: scroll > 600 ? 999 : -50 }">
    <div class="qq item">
      <span class="iconfont icon-qq-copy"></span>
      <div class="tooltip">
        <p>在线咨询</p>
        <b id="contactQQ" @click="qq">3229192850</b>
      </div>
    </div>
    <div class="phone item">
      <span class="iconfont icon-76"></span>
      <div class="tooltip">
        <p>联系电话</p>
        <b>400-878-6390</b>
      </div>
    </div>
    <div class="wx item">
      <span class="iconfont icon-weixin"></span>
      <div class="tooltip">
        <p>官方微信服务号</p>
        <img src="../../assets/images/wx.jpg" alt="" />
      </div>
    </div>
    <div class="top item" @click="backTop()">
      <span class="iconfont icon-jiantou_xiayiye"></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scroll: 0 // 滚动条位置
    }
  },
  methods: {
    scrollTop() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
    },
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.scroll = 0
    },
    qq() {
      window.BizQQWPA.addCustom({
        aty: '0', // 指定工号类型,0-自动分流，1-指定工号，2-指定分组
        nameAccount: '2557300127', // 指定的聊天 QQ 号码
        selector: 'contactQQ' // 触发聊天的标签id
      })
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollTop, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollTop)
  }
}
</script>

<style lang="scss" scoped>
.flxed-bar {
  width: 72px;
  height: 216px;
  position: fixed;
  right: 40px;
  bottom: 20%;
  transition: all 0.3s;
  z-index: 999;
  .item {
    width: 100%;
    height: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    background: #fff;
    transition: all 0.3s;
    border: 1px solid #dcdcdc;
    cursor: pointer;
    position: relative;
    span {
      font-size: 26px;
    }
    .tooltip {
      position: absolute;
      left: -220px;
      width: 200px;
      height: 100px;
      background: #999999;
      border-radius: 5px;
      text-align: center;
      padding: 15px;
      transition: all 0.3s;
      transform: scale(0);
      &::after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        right: -9px;
        top: 50%;
        margin-top: -8px;
        transform: rotateZ(45deg);
      }
    }
    &:hover {
      background: #2463ef;
      color: #fff;
      border-color: #2463ef;
      .tooltip {
        transform: scale(1);
      }
    }
  }
  .qq {
    border-radius: 5px 5px 0 0;
    border-bottom: none;
  }
  .qq,
  .phone {
    .tooltip {
      width: 200px;
      height: 80px;
      background: #2463ef;
      color: #fff;
      &::after {
        background: #2463ef;
      }
      b {
        font-size: 24px;
        margin-top: 10px;
      }
    }
  }
  .phone {
    span {
      font-size: 34px;
    }
  }
  .wx {
    border-radius: 0 0 5px 5px;
    border-top: none;
    .tooltip {
      width: 150px;
      height: 175px;
      left: -170px;
      background: #fff;
      border: 1px solid #dcdcdc;
      color: #999999;
      padding: 10px;
      &::after {
        background: #fff;
        border-right: 1px solid #dcdcdc;
        border-top: 1px solid #dcdcdc;
      }
      img {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  .top {
    margin-top: 20px;
    border-radius: 5px;
    span {
      font-size: 30px;
    }
  }
}
</style>
