import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles/index.scss'
import './assets/icons/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'animate.css'
import 'animate.css/animate.compat.css'
import {
  Button,
  Carousel,
  CarouselItem,
  Row,
  Col,
  Popover,
  Pagination,
  Dialog,
  Form,
  FormItem,
  Input,
  RadioGroup,
  Radio,
  Autocomplete
} from 'element-ui'

import CButton from './components/CButton/index.vue'
import FootForm from './components/FootForm/index.vue'

import WOW from 'wow.js'
const wow = new WOW({})
wow.init()

Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Popover)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Autocomplete)

Vue.component('c-button', CButton)
Vue.component('FootForm', FootForm)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
